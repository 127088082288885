import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import styled from "styled-components"

const Styling = styled.section`
  margin: 2rem 0;
  .single-item-per-row-view {
    display: block;
  }
  .multiple-item-per-row-view {
    display: none;
  }
  @media (min-width: 992px) {
    .single-item-per-row-view {
      display: none;
    }
    .multiple-item-per-row-view {
      display: block;
    }
  }
`

const Title = styled.div`
  text-align: center;
  margin: 0 2rem;
`

const RowOfServices = styled.div`
  margin-bottom: 1rem;
`

const Component = styled.div`
  padding: 34px 50px 30px;
  margin: 0 20px;
  text-align: center;
  border-bottom: 3px solid #0e2b89;
  position: relative;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  height: 100%;
`

const ComponentMobile = styled.div`
  .title-description {
    margin-top: -105px !important;
    padding: 34px 50px 30px;
    margin: 0 20px;
    text-align: center;
    border-bottom: 3px solid #0e2b89;
    position: relative;
    transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    background-color: #fff;
    box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
  }
  margin-bottom: 2rem;
`

const DescriptionRow = styled.div`
  margin-top: -55px;
`

const JustifyCenter = styled.div`
  display: flex;
  justify-content: center;
`

export default function Services(props) {
  // group the services prop by groups of 3 e.g. [ {}, {}, {}, {}, ... ] -> [ [{}, {}, {}], [{}, {}, {}], ... ]
  let services = []
  for (
    let i = 0;
    i < props.services.length - (props.services.length % 3);
    i += 3
  ) {
    // create groups of 3 services
    const group = [
      props.services[i],
      props.services[i + 1],
      props.services[i + 2],
    ]
    services.push(group)
  }

  // push any remaining services
  const finalRow = []
  for (
    let i = props.services.length - (props.services.length % 3);
    i < props.services.length;
    i++
  ) {
    finalRow.push(props.services[i])
  }
  services.push(finalRow)

  return (
    <Styling>
      <Container>
        <Row>
          <Col>
            <Title>
              <h3>{props.title}</h3>
            </Title>
          </Col>
        </Row>
        {services.map((rowOfServices, index) => (
          <>
            {/* single item row view (mobile) */}
            <RowOfServices key={index} className="single-item-per-row-view">
              <Row>
                {props.services.map((service, index) => (
                  <Col md="6" lg="4" key={index}>
                    <ComponentMobile>
                      <Img
                        fixed={service.image}
                        className="img-fluid"
                        alt={"alt text"}
                      />
                      <div className="title-description">
                        <h5>{service.title}</h5>
                        <p>{service.description}</p>
                      </div>
                    </ComponentMobile>
                  </Col>
                ))}
              </Row>
            </RowOfServices>
            {/* multi item row view (tablet, desktop) */}
            <RowOfServices key={index} className="multiple-item-per-row-view">
              <Row>
                {rowOfServices.length === 3 ? (
                  rowOfServices.map((service, index) => (
                    <Col md="6" lg="4" key={index}>
                      <Img
                        fixed={service.image}
                        className="img-fluid"
                        alt={"alt text"}
                      />
                    </Col>
                  ))
                ) : rowOfServices.length === 2 ? (
                  <>
                    <Col md="6" lg={{ span: "4", offset: "2" }}>
                      <JustifyCenter>
                        <Img
                          fixed={rowOfServices[0].image}
                          className="img-fluid"
                          alt={"alt text"}
                        />
                      </JustifyCenter>
                    </Col>
                    <Col md="6" lg="4">
                      <JustifyCenter>
                        <Img
                          fixed={rowOfServices[1].image}
                          className="img-fluid"
                          alt={"alt text"}
                        />
                      </JustifyCenter>
                    </Col>
                  </>
                ) : rowOfServices.length === 1 ? (
                  rowOfServices.map((service, index) => (
                    <Col md="6" lg={{ span: "4", offset: "4" }} key={index}>
                      <JustifyCenter>
                        <Img
                          fixed={service.image}
                          className="img-fluid"
                          alt={"alt text"}
                        />
                      </JustifyCenter>
                    </Col>
                  ))
                ) : (
                  console.error("service component bug")
                )}
              </Row>
              <DescriptionRow>
                <Row>
                  {rowOfServices.length === 3 ? (
                    rowOfServices.map((service, index) => (
                      <Col md="6" lg="4" key={index}>
                        <Component>
                          <h5>{service.title}</h5>
                          <p>{service.description}</p>
                        </Component>
                      </Col>
                    ))
                  ) : rowOfServices.length === 2 ? (
                    <>
                      <Col md="6" lg={{ span: "4", offset: "2" }}>
                        <Component>
                          <h5>{rowOfServices[0].title}</h5>
                          <p>{rowOfServices[0].description}</p>
                        </Component>
                      </Col>
                      <Col md="6" lg="4">
                        <Component>
                          <h5>{rowOfServices[1].title}</h5>
                          <p>{rowOfServices[1].description}</p>
                        </Component>
                      </Col>
                    </>
                  ) : rowOfServices.length === 1 ? (
                    rowOfServices.map((service, index) => (
                      <Col md="6" lg={{ span: "4", offset: "4" }} key={index}>
                        <JustifyCenter>
                          <Component>
                            <h5>{service.title}</h5>
                            <p>{service.description}</p>
                          </Component>
                        </JustifyCenter>
                      </Col>
                    ))
                  ) : (
                    console.error("service component bug")
                  )}
                </Row>
              </DescriptionRow>
            </RowOfServices>
          </>
        ))}
      </Container>
    </Styling>
  )
}
