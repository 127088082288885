import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"

const Card = styled.section`
  margin: 4rem 0;
  padding: 2.5rem 1.5rem;
  box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.3);
`

const List = styled.ul`
  padding-left: 0;
  li {
    color: #263045;
    padding-left: 24px;
    list-style: none;
    position: relative;
    padding-bottom: 10px;
    font-size: 16px;
    i,
    svg {
      position: absolute;
      left: 0px;
      top: 5px;
    }
  }
`

export default function PeaceOfMindGuarantee() {
  const data = useStaticQuery(graphql`
    query peaceOfMindGuarantee {
      file(relativePath: { eq: "banner/AdobeStock_200817582.jpeg" }) {
        childImageSharp {
          fixed(height: 220) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
    }
  `)

  return (
    <Card>
      <Row>
        <Col md="4">
          <div className="ttm_single_image-wrapper">
            <Img
              fixed={data.file.childImageSharp.fixed}
              className="img-fluid"
              alt="thumbs up"
            />
          </div>
        </Col>
        <Col md="8">
          <h3>Compulease "Peace of Mind" Guarantee</h3>
          <List>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} />
              <span>
                Guarantee against obsolescence with equipment refresh programme
              </span>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} />
              <span>
                Up to 5 years on-site manufacturer’s warranty on selected
                notebooks
              </span>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} />
              <span>Optional Equipment Protection Cover</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} />
              <span>Personal service from a dedicated account manager</span>
            </li>
          </List>
        </Col>
      </Row>
    </Card>
  )
}
