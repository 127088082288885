import React from "react"
import Layout from "../../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faServer, faWrench, faCogs } from "@fortawesome/free-solid-svg-icons"
import PartnerCarousel from "../../../components/PartnerCarousel"
import PeaceOfMindGuarantee from "../../../components/PeaceOfMindGuarantee"
import SectionTitle from "../../../components/SectionTitle"
import IconCards from "../../../components/business/IconCards"
import Services from "../../../components/Services"
import { Banner } from "../../../components/Banner"
import BusinessOffers from "../../../components/business/BusinessOffers"
import SEO from "../../../components/seo"

export default function PCsAndServers() {
  const cards = [
    {
      icon: <FontAwesomeIcon icon={faServer} />,
      title: "Servers",
      description:
        "We can supply a complete range of “tailor-made” server solutions including full installation, cabling and configuration.",
    },
    {
      icon: <FontAwesomeIcon icon={faWrench} />,
      title: "Warranty, Maintenance & Support",
      description:
        "All PCs are supplied with a manufacturer’s 3 Year “Next Business Day” on-site warranty, in addition to the technical support. Full maintenance packages are available upon request.",
    },
    {
      icon: <FontAwesomeIcon icon={faCogs} />,
      title: "Install to Desk & Imaging Facility",
      description:
        "Where requested, we can “install to desk” or “image” your new PCs with your chosen configuration, so they’re ready to use and connectable to your network as you take them out of the box, saving you valuable time!",
    },
  ]

  const data = useStaticQuery(graphql`
    query businessPCsAndServers {
      laptopsAndTablets: file(
        relativePath: { eq: "services/AdobeStock_274709086.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      storage: file(
        relativePath: { eq: "services/AdobeStock_101536916.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      ITNetworks: file(
        relativePath: { eq: "services/AdobeStock_119201395.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Software: file(
        relativePath: { eq: "services/AdobeStock_317550082.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Furniture: file(
        relativePath: { eq: "services/AdobeStock_48949475.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Printers: file(
        relativePath: { eq: "services/AdobeStock_255540819.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Maintenance: file(
        relativePath: { eq: "services/AdobeStock_308707407.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      NetworkSecurity: file(
        relativePath: { eq: "services/AdobeStock_46647398.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_297400808.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "Laptops & Tablets",
      description: "iMacs, Macbooks, Google Chromebooks and PCs",
      image: data.laptopsAndTablets.childImageSharp.fixed,
    },
    {
      title: "Storage",
      description: "Backup, NAS, Airport Time Capsule",
      image: data.storage.childImageSharp.fixed,
    },
    {
      title: "IT Networks",
      description: "Servers, Storage, Cabling, Switches and Web Filtering",
      image: data.ITNetworks.childImageSharp.fixed,
    },
    {
      title: "Software",
      description:
        "Mobile Device Management, Microsoft, Operating Systems, Adobe, User Monitoring",
      image: data.Software.childImageSharp.fixed,
    },
    {
      title: "Furniture",
      description: "Desking, IT Suites, Storage Cupboards",
      image: data.Furniture.childImageSharp.fixed,
    },
    {
      title: "Printers",
      description:
        "Mono Laser Printers, Colour Laser Printers, Multifunction Printers, Inkjet Printer",
      image: data.Printers.childImageSharp.fixed,
    },
    {
      title: "Maintenance",
      description:
        "Extended Warranties, Telephone Support, Remote Support, Onsite Support, Installation",
      image: data.Maintenance.childImageSharp.fixed,
    },
    {
      title: "Network Security",
      description: "Web Filtering, Antivirus, Server Racks",
      image: data.NetworkSecurity.childImageSharp.fixed,
    },
  ]

  return (
    <Layout headerType="business">
      <SEO title="Compulease · PCs and Servers Business" />
      <BusinessOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              title="PCs and Servers"
              description={
                <>
                  <p>
                    Equipping your business with the latest technology doesn’t
                    have to be as expensive as you think. You can equip an
                    office with a latest state-of-the-art, 5-user network
                    including new server, new PCs and printer from as little as
                    £29 a week.
                  </p>
                  <p>
                    We appreciate every business is unique and has its own
                    specific needs. So whether your requirement is for a single
                    PC, a fully integrated network including Server or a 1500 PC
                    roll-out to equip a new division at corporate headquarters,
                    Compulease™ will design a specific “bespoke” solution for
                    you.
                  </p>
                  <p>
                    All PC solutions come with our highly acclaimed “Support
                    Package” to provide you with complete peace of mind.
                  </p>
                </>
              }
            />
          </Col>
        </Row>
      </Container>

      <IconCards
        title="We’re ready to back you and your business"
        cards={cards}
      />

      <Container>
        <PeaceOfMindGuarantee />
        <Services title="Solutions we provide" services={services} />
        <Banner
          image={data.BannerImage.childImageSharp.fluid}
          title="Speak to our friendly customer service team for all your business computer leasing and finance needs"
          link={{
            text: "Get in touch",
            to: "/business/ContactUs/",
          }}
        />
        <PartnerCarousel type="business" />
      </Container>
    </Layout>
  )
}
